<template>
  <div class="page-thank-you">
    <div class="container">
      <h1 class="thank-you__title">Merci pour votre demande!</h1>
      <p class="thank-you__body">
        Les professionnels vous contacteront dans les plus brefs délais.
      </p>
    </div>

    <div class="container">
      <div id="usps" class="usps__container">
        <h2 class="usps__title">Quelques conseils pour réussir</h2>
        <Usps :usps="usps" />
      </div>
    </div>
  </div>
</template>

<script>
import Usps from 'chimera/all/themes/blueflow/components/Usps'
import AbstractPage from 'chimera/all/components/page/AbstractPage'

export default {
  components: {
    Usps,
  },

  extends: AbstractPage,

  /**
   * @returns {object}
   */
  data() {
    return {
      headTitle: 'Demande complétée',
      headDescription:
        "Entrez votre code postal et comparez gratuitement 6 devis des meilleures entreprises de votre région. ✓ Le meilleur prix pour votre fournisseur de panneaux solaires ✓ Économisez jusqu'à 40% ✓ 100% gratuit!",
      path: '/quotes-requests/demande-completee',
      usps: [
        {
          text: "Assurez-vous d'avoir votre téléphone et votre agenda à portée de main, car les professionnels peuvent vous contacter par téléphone.",
          logo: require('chimera/all/themes/blueflow/images/usps/calendar.svg'),
        },
        {
          text: 'Ne regardez pas seulement le prix, mais comparez aussi la qualité, le service et la fiablilité.',
          logo: require('chimera/all/themes/blueflow/images/usps/offer.svg'),
        },
        {
          text: 'Prenez votre temps pour choisir le bon professionnel.',
          logo: require('chimera/all/themes/blueflow/images/usps/safety.svg'),
        },
      ],
    }
  },
}
</script>
